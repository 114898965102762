var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"color":"#f8a229","loader":"bars","width":128,"height":128,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"items-per-page":30,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [5, 10, 15, 30],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.startTime",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.dateFormat(item.startTime)))])]}},{key:"item.endTime",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.dateFormat(item.endTime)))])]}},{key:"item.totalTime",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.totalTime.toFixed(2)))])]}}],null,true)}),_c('v-col',{staticClass:"text-right pa-0"},[(_vm.totalItems > 0)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.download}},[_vm._v(" Download ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }