<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="bars"
      :width="128"
      :height="128"
      :is-full-page="false"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :items-per-page="30"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [5, 10, 15, 30],
      }"
    >
      <template v-slot:[`item.startTime`]="{ item }">
        <div>{{ dateFormat(item.startTime) }}</div>
      </template>
      <template v-slot:[`item.endTime`]="{ item }">
        <div>{{ dateFormat(item.endTime) }}</div>
      </template>
      <template v-slot:[`item.totalTime`]="{ item }">
        <div>{{ item.totalTime.toFixed(2) }}</div>
      </template>
    </v-data-table>
    <v-col class="text-right pa-0">
      <v-btn color="primary" v-if="totalItems > 0" @click="download">
        Download
      </v-btn>
    </v-col>
  </div>
</template>
<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "UsersReport",
  components: {
    Loading,
  },
  props: ["filter"],
  data: () => ({
    isLoading: false,
    totalItems: 0,
    loading: false,
    options: {},
    items: [],
    pageItems: [],
    headers: [
      { text: "No of Orders", value: "noOfOrders" },
      { text: "Picked by", value: "pickedBy" },
      { text: "Start time", value: "startTime" },
      { text: "End time", value: "endTime" },
      { text: "Total time (mins)", value: "totalTime" },
    ],
    searchTimer: null,
  }),

  watch: {
    options: {
      handler(val, oldVal) {
        if (oldVal.page) {
          if (val.itemsPerPage !== oldVal.itemsPerPage) {
            this.pageItems = [];
          }
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    filter: {
      handler(val, oldVal) {
        if (val.generate !== oldVal.generate) {
          this.pageItems = [];
          this.options.page = 1;
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },

  methods: {
    getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let pages = [page];
      let lastPage = Math.ceil(this.totalItems / itemsPerPage);
      pages.push(lastPage);

      if (this.totalItems > 0) {
        let nextPage = page + 1 < lastPage ? page + 1 : page;
        let afterNextPage = nextPage + 1 < lastPage ? nextPage + 1 : nextPage;
        pages.push(nextPage, afterNextPage);
      } else {
        let nextPage = page + 1;
        let afterNextPage = page + 2;
        pages.push(nextPage);
        pages.push(afterNextPage);
      }

      pages.forEach((pageNumber) => {
        if (
          pageNumber > 0 &&
          !this.pageItems.some((x) => x.page === pageNumber)
        ) {
          this.pageItems.push({
            page: pageNumber,
            items: this.getData(pageNumber, itemsPerPage),
          });
        }
      });

      let pageItems = this.pageItems.find((x) => x.page === page);
      if (pageItems) {
        this.isLoading = true;
        this.loading = true;
        pageItems.items
          .then((response) => {
            if (response.data.success) {
              this.items = response.data.data.items;
              this.totalItems = response.data.data.totalCount;
            }
            this.loading = false;
            this.isLoading = false;
          })
          .catch(() => {
            this.items = [];
            this.totalItems = 0;
            this.loading = false;
            this.isLoading = false;
          });
      }
    },
    getData(page, itemsPerPage) {
      return new Promise(async (resolve, reject) => {
        try {
          var data = {
            pageNumber: page,
            pageSize: itemsPerPage,
            search: this.filter.search,
            from: this.filter.fromDate,
            to: this.filter.toDate,
            csvId: this.filter.csv > 0 ? this.filter.csv : "",
          };
          let response = await this.$http.post(`Reports/Users`, data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    dateFormat(date) {
      if (date) {
        return moment.utc(date).local().format("h:mm:ss a DD.MM.YYYY");
      } else {
        return "";
      }
    },

    getStatusName(id) {
      if (this.filter.statusList && id > 0) {
        let status = this.filter.statusList.find((x) => x.id === id);
        if (status) {
          return status.statusInText;
        }
        return "";
      }
    },
    download() {
      this.isLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      var data = {
        pageNumber: page,
        pageSize: this.totalItems,
        search: this.filter.search,
        from: this.filter.fromDate,
        to: this.filter.toDate,
        csvId: this.filter.csv > 0 ? this.filter.csv : "",
      };

      this.$http
        .post(`Reports/Users/Download`, data)
        .then((response) => {
          var fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute(
            "download",
            "myOrders_Users_Report_" +
              moment().format("YYYY_MM_DD_T_hh_mm_ss") +
              ".csv"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
  },
};
</script>
